// Shamelessly copied from the
interface TableRow {
  children: HTMLElement[];
}

interface Sortable {
  sortBy: string;
  sortAsc: boolean;
  sortDir: string;
  $event: Event;
  sortByColumn(sort_by: string): void;
  getTableRows(): TableRow[];
  getCellValue(row: TableRow, index: number): string;
  sortCallback(index: number): (a: TableRow, b: TableRow) => number;
}
export default (): Sortable => ({
  sortBy: '',
  $event: new Event(''),
  sortAsc: false,
  sortDir: 'desc', // Default value
  sortByColumn(sort_by: string) {
    this.sortDir = this.sortAsc ? 'asc' : 'desc'; // Update sortDir based on sortAsc=

    const target = this.$event.target as HTMLElement;
    const sort_by_val = sort_by;
    if (!target) {
      return;
    }
    if (this.sortBy === sort_by_val) {
      if (this.sortAsc) {
        this.sortBy = '';
        this.sortAsc = false;
      } else {
        this.sortAsc = !this.sortAsc;
      }
    } else {
      this.sortBy = sort_by_val;
    }

    const current_th_row = target.closest('tr');
    const current_th_cell = target.closest('th');
    if (!current_th_row || !current_th_cell) {
      return;
    }
    let rows = this.getTableRows()
      .sort(
        this.sortCallback(
          Array.from(current_th_row?.querySelectorAll('th') ?? []).indexOf(
            current_th_cell
          )
        )
      )
      .forEach((tr) => {
        // @ts-expect-error
        this.$refs.tbody.appendChild(tr);
      });
  },
  getTableRows() {
    // @ts-expect-error
    return Array.from(this.$refs.tbody.querySelectorAll('tr')) as TableRow[];
  },
  getCellValue(row: TableRow, index: number): string {
    if (!row) {
      return '';
    }

    const cell = row.children[index] as HTMLElement;
    return cell.getAttribute('data-sort-value') ?? cell.innerText;
  },

  sortCallback(index: number) {
    return (a: TableRow, b: TableRow) =>
      ((row1, row2) => {
        return row1 !== '' &&
          row2 !== '' &&
          !isNaN(Number(row1)) &&
          !isNaN(Number(row2))
          ? Number(row1) - Number(row2)
          : row1.toString().localeCompare(row2);
      })(
        this.getCellValue(this.sortAsc ? a : b, index),
        this.getCellValue(this.sortAsc ? b : a, index)
      );
  },
});
